/*
Theme Name: 室町きぬがわ
Author: warrior_ito
Description: .
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
*/

@charset "utf-8";
@import "moto_style";
$theme_color: #FF3300;
$link_color: blue;
$text_color: #5E5244;
$base_color: #CDDE9E;
//$theme_fonts: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$theme_fonts: YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
//$headline_fonts: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$headline_fonts: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$content_width:1100px;

@mixin cf{
  &:after{
    content:" ";
    display:block;
    clear:both;
  }
}

body{
  font-family: $theme_fonts;
  color: $text_color;
  line-height:1.7;
  font-size:16px;
  background-color: $base_color;
}
a{
  text-decoration: none;
}

.wrap{
  max-width: 1320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.archive{
  .content{
    max-width: 1060px;
    padding: 1em;
    @media screen and (min-width: 769px) {
      padding: 50px;
    }
    box-sizing: border-box;
    margin-left: auto;
  }
}

.single{
  .btn__wrap{
    text-align: center;
  }
}

#sidebar{
  padding: 2em 0;
  text-align: center;
  .widget{
    display: inline-block;
    .widgettitle{
      display: none;
    }
    label{
      display: none;
    }
    select{
      padding: 0.5em;
    }
  }
}

.btn__wrap{
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 15px;
  }
  a{
    display: inline-block;
    padding: 0.3em 0.6em;
    background-color: #ffffff;
    border: solid 1px #DCC57A;
    border-radius: 5px;
    min-width: 190px;
    text-align: center;
    position: relative;
    &:after{
      font-family: "Font Awesome 5 Free";
      content:"\f105";
      font-weight: 900;
      position: absolute;
      display: block;
      line-height: 1;
      right: 10px;
      height: 16px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.post{
  @include cf;
  border-bottom: solid 3px #C9C0AF;
  margin-bottom: 3em;
  &__title{
    font-size:20px;
    margin-bottom: 10px;
    line-height: 1;
    font-family: $headline_fonts;
    font-weight: bold;
    font-size:28px;
    @media screen and (min-width: 415px) {
      padding: 0 20px 0 0;
    }
  }
  &__content{
    @media screen and (min-width: 415px) {
      padding: 0 20px 20px 0;
    }
  }
  &__wrap{
    max-width:70%;
    //padding: 20px;
    @media screen and (min-width: 769px) {
      max-width:656px;
    }
    @media screen and (max-width: 414px) {
      max-width: 100%;
    }
    box-sizing: border-box;
    float: left;
  }
  &__figure{
    max-width:30%;
    text-align: center;
    @media screen and (max-width: 414px) {
      max-width: 100%;
    }
    @media screen and (min-width: 415px) {
      float: right;
    }
    @media screen and (min-width: 769px) {
      max-width:300px;
    }
  }
  &__subcon{
    box-sizing: border-box;
    @include cf;
    padding: 20px 20px 20px 0;
    border-top: solid 1px #C9C0AF;
    font-size:14px;
    .data{
      @media screen and (min-width: 415px) {
        float: left;
        margin: 0;
      }
    }
    .category{
      @media screen and (min-width: 415px) {
        float: right;
        margin: 0;
      }
      a{
        display: inline-block;
      }
    }
  }
}

#global-nav{
  font-family: $headline_fonts;
}

@media screen and (min-width: 769px) {
  .globalNav{
    display: none;
  }
  .sp_btn{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #global-nav{
    display: none;
  }
  .sp_btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url(http://kyoto-kinugawa.com/wp/wp-content/uploads/menu_02.png);
    z-index: 9999;
    a{
      display: inline-block;
      width: calc(100% / 3);
      text-align: center;
      padding: 1em 0;
      color: #fff!important;
    }
  }
  .cm_totop{
    display: none!important;
  }
  .h_tel{
    display: none;
  }
  .navwrap{
    position: fixed;
    right: -100%;
    top: 25px;
  }
  #site-logo{
    margin-bottom: 0;
    img{
      max-width: 50px;
    }
  }
  #headerwrap{
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .f_nav{
    display: block;
    padding: 10px;
    .f_nav_col{
      width: 100%;
    }
  }
}

.singlepost{
  margin-bottom: 60px;
  &__figure{
    margin-top: 2em;
  }
  &__subcon{
    @include cf;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    .data{
      @media screen and (min-width: 415px) {
        float: left;
        margin: 0;
      }
    }
    .category{
      @media screen and (min-width: 415px) {
        float: right;
        margin: 0;
      }
      a{
        display: inline-block;
      }
    }
  }
}

.title{
  &__archive{
    font-size: 36px;
    border-bottom: solid 1px #C9C0AF;
    font-family: $headline_fonts;
  }
}

.pagenation{
  text-align: center;
  li{
    display: inline-block;
    background-color: #C9C0AF;
    a{
      padding: 0.2em 0.5em;
      display: inline-block;
    }
    &.active{
      padding: 0.2em 0.5em;
      a{
        padding: 0;
      }
    }
  }
}

.content{
  &_wrap{
    padding: 2em;
  }
}

.fontweight-bold{
  font-weight: bold;
}

.site{
  font-family: $theme_fonts;
}
.module-title,.mincho{
  font-weight: bold;
  font-family: $headline_fonts;
}

strong{
  font-weight: bold;
}

.menu-globalnav-container{
  font-size:18px;
  a{
    display: block;
    padding: 0.5em;
    color: #fff;
    border-bottom: solid 1px #fff;
  }
}
.responsive-nav-container{
  &.on{
    .navwrap{
      right: 0;
      transition: left .5s, right .5s;
      .menu-globalnav-container{
        a{
          color: #fff;
        }
      }
    }
  }
}

.third{
  td{
    width: 22%;
    &+td{
      width: 34%;
    }
  }
}

.ie{
  .site{
    background-attachment: scroll;
  }
}